import React, { memo, useEffect, useMemo, useState } from 'react';
import { Router } from '@reach/router';
import { dashboardRoutes } from '@data/navigation';
import PrivateRoute from '@components/Routes/PrivateRoute';
import { RoutesContext } from '@helpers/contexts';
import { getFlatRoutes, getRouteStack } from '@helpers/routes';
import { Cards } from '@models/card';
import { ContactPhone, CreditCard, GroupAdd, Help, Receipt } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import { cardColours } from '@helpers/common';
import { Skeleton } from '@mui/material';
import { makeGetRequest } from '@helpers/requests';
import { CARDS } from '@helpers/api';
import { useSnackbar } from '@components/common/Snackbar';
import { useTheme } from '@mui/styles';
// import whyDidYouRender from '@welldone-software/why-did-you-render';
// import Redux from 'react-redux';

// whyDidYouRender(React, {
// 	trackAllPureComponents: true,
// 	trackExtraHooks: [],
// });

const Dashboard = () => {
	const [openSnackbar] = useSnackbar();
	const theme = useTheme();
	const routes = dashboardRoutes();
	const flatRoutes = getFlatRoutes(routes);
	const routeStack = getRouteStack(routes);
	const currentRouteItem = routeStack?.[routeStack?.length - 1];
	const [cards, setCards] = useState<Cards>([]);

	useEffect(() => {
		(async () => {
			try {
				const { data: cardsData } = await makeGetRequest(CARDS, {}, {}, true);
				setCards(cardsData);
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ??
							'An error occurred attempting to retrieve your cards.'
					);
				}
			}
		})();
	}, []);

	const navigation = [
		{
			title: 'General',
			items: [
				// {
				// 	name: 'Dashboard',
				// 	path: '/dashboard',
				// 	icon: <DashboardIcon />,
				// },
				{
					name: 'Cards',
					path: '/dashboard/cards',
					icon: <CreditCard />,
				},
				{
					name: 'Link Hubs',
					path: '/dashboard/link-hubs',
					icon: <LinkIcon />,
				},
				{
					name: 'Contact Cards',
					path: '/dashboard/contact-cards',
					icon: <ContactPhone />,
				},
				{
					name: 'Forms',
					path: '/dashboard/forms',
					icon: <Receipt />,
				},
			],
		},
		{
			title: 'Cards',
			items: cards?.map((card) => {
				const events =
					(card?.analytics?.cardTaps ?? 0) +
					(card?.analytics?.qrScans ?? 0) +
					(card?.analytics?.contactsAdded ?? 0);
				return {
					name: card?.name ?? '',
					description: !!events ? `${events} events` : 'start networking!',
					path: `/dashboard/cards/${card?._id}`,
					icon: (
						<Skeleton
							sx={{
								backgroundColor: cardColours?.[card?.detail?.colour],
								border: `2px solid ${
									theme.palette.common[
										theme.palette.mode === 'dark' ? 'white' : 'black'
									]
								}`,
							}}
							variant="circular"
							animation={false}
							width={20}
							height={20}
						/>
					),
					showArrow: true,
				};
			}),
		},
		{
			title: 'Leads',
			items: [
				{
					name: 'Contacts',
					path: '/dashboard/contacts',
					icon: <ContactPhone />,
				},
				{
					name: 'Analytics',
					path: '/dashboard/analytics',
					icon: <ContactPhone />,
				},
			],
		},
		{
			title: 'Other',
			items: [
				{
					name: 'Affiliates',
					path: '/dashboard/affiliate',
					icon: <GroupAdd />,
				},
				{
					name: 'Help',
					path: '/help',
					icon: <Help />,
				},
			],
		},
	];

	return (
		<RoutesContext.Provider
			value={{
				routes,
				routeStack,
				navigation,
				currentRouteItem,
				flatRoutes,
			}}
		>
			<Router>
				{flatRoutes?.map(({ path, component: Component, permissions }) => (
					<PrivateRoute
						key={path}
						path={path}
						component={Component}
						permissions={permissions}
					/>
				))}
				{/*<Error404 default />*/}
			</Router>
		</RoutesContext.Provider>
	);
};

export default Dashboard;
